<script>
import Base from '@backend/Base.vue';

import BOGen from '@helper/BOGen';

export default {
	name: "BoBmi",
	extends: Base,
  data(){
    return {
		Name:"BoBmi",
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
	this.refreshData()
	},
  watch:{
    '$route.query'(){
		this.refreshData()
	},
    'filter.level'(v){
		if(!v) return
		this.search()
	},
  }
}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		
		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
             <div class="card">
                <div class="card-body">
	                <VForm @resp="search">
						<div class="row">
							<div class="col-sm-5">
								<h5 class="card-title">{{ObjectName}} List</h5>
							</div>
							<div class="col-sm-3">
							</div>
							<div class="col-sm-3">
								<div class="form-group mb-0">
                                    <div class="input-group">
                                       <input type="text" v-model="filter.search" v-on:keyup.enter="search"  class="form-control" placeholder="Search...">
                                       <div class="input-group-append">
                                          <button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
                                       </div>
                                    </div>
                                 </div>
							</div>
							<div class="col-sm-1">
								<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
							</div>
						</div>
					</VForm>
                </div>
                <div class="table-responsive ajax-table">
					<table class="table table-striped table-bordered">
						<thead>
							<tr>
								<th>#</th>
								<th>{{fields.mb_param1}}</th>
								<th>{{fields.mb_param2}}</th>
								<th>{{fields.mb_criteria}}</th>
								<th>{{fields.mb_is_active}}</th>
								<th width="120px">Action</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(v,k) in data.data" :key="k">
								<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
								<td>{{v.mb_param1}}</td>
								<td>{{v.mb_param2}}</td>
								<td>{{v.mb_criteria}}</td>
								<td><StatusLabel :status="v.status"></StatusLabel></td>
								<td class="btn-action">
									<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
									<a v-if="page.moduleRole('ChangeStatus')" href="javascript:;" class="icon_action" @click="changeStatus(k)" v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
									<a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
								</td>
							</tr>
							<tr v-if="NotFound">
								<td colspan="99">
									<h3 class="tc">{{NotFound}}</h3>
								</td>
							</tr>
							<tr v-if="data.data===false">
								<td colspan="99">
									<LoadingSpinner light></LoadingSpinner>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="panel-body">
					<div class="pull-right">
						<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
             </div>
          </div>
       </div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
                      <div class="col-md-8">
                         <h5 class="card-title">{{(row.id?"Edit":"Add")+" BMI Result"}}</h5>
                      </div>
                   </div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-5">
							<BoField name="mb_param1" :atrr="{type:'number'}" v-model="row.mb_param1"></BoField>
							<BoField name="mb_param2" :atrr="{type:'number'}" v-model="row.mb_param2"></BoField>
							<BoField name="mb_criteria" v-model="row.mb_criteria"></BoField>
						</div>
						<div class="col-sm-10">
							<BoField name="mb_result" v-model="row.mb_result">
								<CKEditor name="mb_result" v-model="row.mb_result" v-bind="validation('mb_result')"></CKEditor>
							</BoField>
							<div class="row">	
								<div class="col-7">							
									<BoField name="mb_is_active">
										<div class="row">
											<radio name="mb_is_active" v-model="row.mb_is_active" option="Y" :attr="validation('mb_is_active')">Active</radio>
											<radio name="mb_is_active" v-model="row.mb_is_active" option="N">Inactive</radio>
										</div>
									</BoField>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>
